import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import { connect } from 'react-redux';

import Web3 from 'web3';
import Safe, { EthersAdapter } from '@safe-global/protocol-kit';
import { ethers } from 'ethers';
import SafeApiKit from '@safe-global/api-kit';
import axios from 'axios';

import './datatables.scss';
import OpModal from './Modals/OpModal.js';
import SuccessModal from './Modals/SuccessModal.js';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import logo from '../../assets/images/logo-dark.png';
import Can from '../../components/Can';
import { truncStringPortion } from '../../helpers/formatter.js';
import {
  fetchERC20config,
  erc20ClosePurchase,
  dictAliases,
  registerERC20Operation,
  proposalDetails,
} from '../../helpers/api';
import EthereumLogo from '../../components/Common/EthereumLogo.js';
import NumberFormat from 'react-number-format';

const abiDecoder = require('abi-decoder');
class EthProposalDetails extends Component {
  constructor() {
    super();
    this.state = {
      tx: null,
      loading: true,
      threshold: 2,
      accepts: [],
      rejects: [],
      signer: null,
      isSigner: null,
      processing: false,
      success: false,
      rejectance: false,
      operationHash: null,
      displayResult: null,
      isAccept: false,
      isReject: false,
      cfgEth: {},
      aliases: [],
      balances: {},
      proposal: {},
      amount: 0,
      formattedAmount: 0,
      referencedAmount: 0,
      toValidate: false,
      invalidAmount: false,
    };
    this.printInvoice.bind(this);
  }

  //Print the Invoice
  printInvoice() {
    window.print();
  }

  connectWallet = async (owner) => {
    this.setState({ signer: owner });
  };

  componentDidMount = async () => {
    let cfgEth = await fetchERC20config();
    await this.setState({ cfgEth: cfgEth.data });
    const res = await dictAliases();
    this.setState({ aliases: res.data });
    const query = new URLSearchParams(this.props.location.search);
    const action = query.get('action');
    if (action === 'reject') await this.setState({ isReject: true });
    const role = this.props.match.params.role;
    const safeTxHash = this.props.match.params.safeTxHash;
    const safeAddr =
      role === 'administrator'
        ? this.state.cfgEth.administrator
        : role === 'master_minter'
        ? this.state.cfgEth.master_minter
        : role === 'owner'
        ? this.state.cfgEth.owner
        : this.state.cfgEth.reserve;
    const transactionServiceUrl = this.state.cfgEth.safe_tx_url;
    const info = await axios.get(
      `${transactionServiceUrl}/api/v1/safes/${safeAddr}`,
    );
    let balances = {};
    for (let owner of info.data.owners) {
      balances[owner] = await this.fetchBalance(owner);
    }
    const threshold = info.data.threshold;
    let tx = await axios.get(
      `${transactionServiceUrl}/api/v1/multisig-transactions/${safeTxHash}`,
    );
    tx = tx.data;
    abiDecoder.addABI(cfgEth.data.abi);
    if (!tx.dataDecoded) {
      try {
        let decodedData = abiDecoder.decodeMethod(tx.data);
        tx.dataDecoded = {
          method: decodedData.name,
          parameters: decodedData.params,
        };
      } catch (e) {
        console.log(e);
      }
    }
    if (
      [
        'mint',
        'burn',
        'transfer',
        'approve',
        'forceTransfer',
        'transfer',
      ].includes(tx.dataDecoded.method)
    ) {
      for (let e of tx.dataDecoded.parameters) {
        if (e.type === 'uint256' && e.name !== '_threshold') {
          this.setState({
            toValidate: true,
            referencedAmount: e.value / this.state.cfgEth.decimals,
          });
        }
      }
    }
    const rejects =
      !tx.dataDecoded &&
      tx.confirmations &&
      tx.confirmations.length >= threshold
        ? tx.confirmations
        : [];
    const accepts = rejects.length === 0 ? tx.confirmations : [];
    const resp = await proposalDetails(safeTxHash);
    this.setState({
      tx: tx,
      threshold: threshold,
      loading: false,
      accepts: accepts,
      rejects: rejects,
      owners: info.data.owners,
      balances: balances,
      role,
      proposal: resp.data,
    });
    /*  console.log(`${cfgEth.data.etherscan}?module=gastracker&action=gasoracle&apikey=${cfgEth.data.api_key}`)
    const gasTracker = await axios.get(
      `${cfgEth.data.etherscan}?module=gastracker&action=gasoracle&apikey=${cfgEth.data.api_key}`,
    );
    console.log(
      `GAS PRICE of ${
        gasTracker.data &&
        gasTracker.data.result &&
        gasTracker.data.result.FastGasPrice
          ? parseInt(gasTracker.data.result.FastGasPrice)
          : 0
      }`,
    ); */
  };

  fetchBalance = async (publicKeyHash) => {
    const web3 = new Web3(this.state.cfgEth.infura_url);
    const balance = parseFloat(
      Number(await web3.eth.getBalance(publicKeyHash)) / Math.pow(10, 18),
    ).toFixed(5);
    return balance;
  };

  acceptOP = async () => {
    if (
      this.state.toValidate &&
      this.state.amount &&
      parseInt(this.state.amount) !== this.state.referencedAmount
    )
      return this.setState({ invalidAmount: true });
    else this.setState({ invalidAmount: false });
    this.setState({ processing: true, isAccept: true, displayResult: null });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const safeOwner = await provider.getSigner();
    if (this.state.signer !== safeOwner.address) {
      this.setState({ isSigner: 'invalid', processing: false });
    } else {
      this.setState({
        isSigner: 'valid',
        info: 'Connecting to Gnosis Safe...',
      });
      const ethAdapter = new EthersAdapter({
        ethers,
        signerOrProvider: safeOwner,
      });

      const safeAddress =
        this.state.role === 'administrator'
          ? this.state.cfgEth.administrator
          : this.state.role === 'master_minter'
          ? this.state.cfgEth.master_minter
          : this.state.role === 'owner'
          ? this.state.cfgEth.owner
          : this.state.cfgEth.reserve;
      const safeSdk = await Safe.create({ ethAdapter, safeAddress });
      const safeService = new SafeApiKit({
        chainId: this.state.cfgEth.network === 'sepolia' ? 11155111 : 1,
      });
      const safeTxHash = this.props.match.params.safeTxHash;
      const tx = await safeService.getTransaction(safeTxHash);
      this.setState({ info: 'Signing the transaction...' });
      try {
        let signature = await safeSdk.signHash(safeTxHash);
        await safeService.confirmTransaction(safeTxHash, signature.data);
        //Save operation
        this._asyncRequest = registerERC20Operation(
          `accept-${tx.confirmations.length + 1}-${
            this.props.match.params.safeTxHash
          }`,
          'accept',
          `Sent accept for ${this.props.match.params.role} proposal`,
          safeOwner.address,
          this.props.match.params.role,
          this.props.match.params.safeTxHash,
        )
          .then((response) => {
            console.log(response.status);
          })
          .catch((error) => {
            console.log(error);
          });
        // EXECUTE IF THRESHOLD REACHED
        if (tx.confirmations.length + 1 >= tx.confirmationsRequired) {
          this.setState({ info: 'Executing operation...' });
          this.executeOp();
        } else
          this.setState({
            processing: false,
            success: true,
            operationHash: null,
            displayResult: 'Proposal successfully accepted',
          });
      } catch (e) {
        this.setState({ info: null, processing: false });
      }
    }
  };

  executeOp = async () => {
    this.setState({
      processing: true,
      isAccept: true,
      displayResult: null,
      info: 'Processing operation on chain...',
    });
    const safeTxHash = this.props.match.params.safeTxHash;
    const provider = new ethers.BrowserProvider(window.ethereum);
    const safeOwner = await provider.getSigner();
    this.setState({ signer: safeOwner.address });
    const ethAdapter = new EthersAdapter({
      ethers,
      signerOrProvider: safeOwner,
    });
    const safeAddress =
      this.state.role === 'administrator'
        ? this.state.cfgEth.administrator
        : this.state.role === 'master_minter'
        ? this.state.cfgEth.master_minter
        : this.state.role === 'owner'
        ? this.state.cfgEth.owner
        : this.state.cfgEth.reserve;
    const safeSdk = await Safe.create({ ethAdapter, safeAddress });
    const safeService = new SafeApiKit({
      chainId: this.state.cfgEth.network === 'sepolia' ? 11155111 : 1,
    });
    const safeTransaction = await safeService.getTransaction(safeTxHash);
    const executeTxResponse = await safeSdk.executeTransaction(safeTransaction);
    const receipt =
      executeTxResponse.transactionResponse &&
      (await executeTxResponse.transactionResponse.wait());
    if (this.props.match.params.role === 'reserve')
      await erc20ClosePurchase(
        'accept',
        this.props.match.params.safeTxHash,
        receipt.hash,
      );
    this.setState({
      processing: false,
      success: true,
      operationHash: receipt.hash,
      displayResult: 'Operation successfully executed',
    });
  };

  rejectOP = async () => {
    if (
      this.state.toValidate &&
      this.state.amount &&
      parseInt(this.state.amount) !== this.state.referencedAmount
    )
      return this.setState({ invalidAmount: true });
    else this.setState({ invalidAmount: false });
    this.setState({ processing: true, isAccept: true, displayResult: null });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const safeOwner = await provider.getSigner();
    if (this.state.signer !== safeOwner.address) {
      this.setState({ isSigner: 'invalid', processing: false });
    } else {
      this.setState({
        isSigner: 'valid',
        info: 'Connecting to Gnosis Safe...',
      });
      const ethAdapter = new EthersAdapter({
        ethers,
        signerOrProvider: safeOwner,
      });

      const safeAddress =
        this.state.role === 'administrator'
          ? this.state.cfgEth.administrator
          : this.state.role === 'master_minter'
          ? this.state.cfgEth.master_minter
          : this.state.role === 'owner'
          ? this.state.cfgEth.owner
          : this.state.cfgEth.reserve;
      const safeSdk = await Safe.create({ ethAdapter, safeAddress });
      const safeService = new SafeApiKit({
        chainId: this.state.cfgEth.network === 'sepolia' ? 11155111 : 1,
      });
      const tx = await safeService.getTransaction(
        this.props.match.params.safeTxHash,
      );
      this.setState({ info: 'Building raw reject operation...' });
      this.setState({
        info: 'Please wait & follow instructions from your Ledger Nano to sign operation...',
      });

      const rejectionTransaction = await safeSdk.createRejectionTransaction(
        tx.nonce,
      );
      const safeTxHash = await safeSdk.getTransactionHash(rejectionTransaction);
      const senderSignature = await safeSdk.signHash(safeTxHash);
      this.setState({ info: 'Publishing reject...' });
      await safeService.proposeTransaction({
        safeAddress,
        safeTransactionData: rejectionTransaction.data,
        safeTxHash,
        senderAddress: safeOwner.address,
        senderSignature: senderSignature.data,
        origin: 'SCEME PF',
      });
      //Save operation
      this._asyncRequest = registerERC20Operation(
        `reject-${safeTxHash}`,
        'reject',
        `Sent reject for ${this.props.match.params.role} proposal`,
        safeOwner.address,
        this.props.match.params.role,
        safeTxHash,
      )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) => {
          console.log(error);
        });
      if (this.props.match.params.role === 'reserve')
        await erc20ClosePurchase('reject', this.props.match.params.safeTxHash);
      this.setState({
        processing: false,
        success: true,
        operationHash: null,
        displayResult: 'Proposal successfully rejected',
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <SuccessModal
              displayResult={this.state.displayResult}
              proposalId={this.props.match.params.safeTxHash}
              isAccept={this.state.isAccept}
              aliases={this.state.aliases}
              selectedWallet={this.state.signer}
            />
            <OpModal
              pending={this.state.processing}
              info={this.state.info}
              passActive={'ledger'}
            />
            <Breadcrumbs
              title="ERC20 Proposal"
              breadcrumbItem={`Proposal for ${
                this.props.match.params.role === 'administrator'
                  ? 'Administrator'
                  : this.props.match.params.role === 'master_minter'
                  ? 'Master minter'
                  : this.props.match.params.role === 'owner'
                  ? 'Owner'
                  : 'Reserve'
              }`}
            />

            <Row>
              {this.state.loading && (
                <div className="text-center my-3">
                  <Link to="#" className="text-primary">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                    Loading{' '}
                  </Link>
                </div>
              )}
              {this.state.tx && (
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <h4 className="float-right font-size-16">
                          <b className="mr-2">
                            {this.state.tx.dataDecoded &&
                              this.state.tx.dataDecoded.method}
                          </b>
                          <small># {this.props.match.params.safeTxHash}</small>
                        </h4>
                        <div className="mb-4">
                          <img src={logo} alt="logo" height="28" />
                        </div>
                      </div>
                      <hr />
                      <div>
                        <p>
                          <b className="mr-2">INFO:</b>
                          {this.state.proposal.description}
                        </p>
                      </div>
                      <hr />
                      <Row>
                        {!this.state.isReject && (
                          <Col md="6">
                            <address>
                              <strong>Approvals:</strong>
                              <br />
                              {this.state.accepts.map((confirmation, index) => (
                                <span key={`appr-${index}`} className="d-block">
                                  {this.state.aliases[
                                    confirmation.owner.toLowerCase()
                                  ] &&
                                  this.state.aliases[
                                    confirmation.owner.toLowerCase()
                                  ].name
                                    ? this.state.aliases[
                                        confirmation.owner.toLowerCase()
                                      ].name
                                    : truncStringPortion(
                                        confirmation.owner,
                                        8,
                                        8,
                                      )}
                                </span>
                              ))}
                            </address>
                          </Col>
                        )}
                        <Col md="6">
                          <address>
                            <strong>Rejects:</strong>
                            <br />
                            {this.state.isReject
                              ? this.state.accepts.map(
                                  (confirmation, index) => (
                                    <span
                                      key={`appr-${index}`}
                                      className="d-block"
                                    >
                                      {this.state.aliases[
                                        confirmation.owner.toLowerCase()
                                      ] &&
                                      this.state.aliases[
                                        confirmation.owner.toLowerCase()
                                      ].name
                                        ? this.state.aliases[
                                            confirmation.owner.toLowerCase()
                                          ].name
                                        : truncStringPortion(
                                            confirmation.owner,
                                            8,
                                            8,
                                          )}
                                    </span>
                                  ),
                                )
                              : this.state.rejects.map(
                                  (confirmation, index) => (
                                    <span
                                      key={`appr-${index}`}
                                      className="d-block"
                                    >
                                      {this.state.aliases[
                                        confirmation.owner.toLowerCase()
                                      ] &&
                                      this.state.aliases[
                                        confirmation.owner.toLowerCase()
                                      ].name
                                        ? this.state.aliases[
                                            confirmation.owner.toLowerCase()
                                          ].name
                                        : truncStringPortion(
                                            confirmation.owner,
                                            8,
                                            8,
                                          )}
                                    </span>
                                  ),
                                )}
                          </address>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col xs="12">
                          <p className="ml-2">
                            <strong>Parameters</strong>
                          </p>
                        </Col>
                        {this.state.tx.dataDecoded &&
                          this.state.tx.dataDecoded.parameters.map((e) => (
                            <Col md="6" key={e.name}>
                              {e.type === 'uint256' && e.name !== '_threshold'
                                ? 'amount'
                                : `${e.name} ${e.type}`}
                              :
                              <strong className="ml-2">
                                {e.type === 'address'
                                  ? this.state.aliases[e.value.toLowerCase()] &&
                                    this.state.aliases[e.value.toLowerCase()]
                                      .name
                                    ? this.state.aliases[e.value.toLowerCase()]
                                        .name
                                    : truncStringPortion(e.value, 8, 8)
                                  : e.type === 'uint256' &&
                                    e.name !== '_threshold'
                                  ? `${(
                                      e.value / this.state.cfgEth.decimals
                                    ).toLocaleString()} EUROP`
                                  : e.value}
                              </strong>
                            </Col>
                          ))}
                      </Row>
                      <hr />
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          Status :
                          <b className="ml-2">
                            {this.state.tx.isExecuted
                              ? 'Closed'
                              : this.state.tx.confirmations.length >=
                                this.state.threshold
                              ? 'Closed'
                              : 'Pending'}
                            <br />
                            {this.state.tx.confirmations.length >=
                            this.state.threshold ? (
                              this.state.tx.isExecuted ? (
                                <span className="badge badge-success font-size-12">
                                  Accepted
                                </span>
                              ) : !this.state.tx.dataDecoded ? (
                                <span className="badge badge-danger font-size-12">
                                  Rejected
                                </span>
                              ) : (
                                <span className="badge badge-info font-size-12">
                                  Awaiting execution
                                </span>
                              )
                            ) : (
                              <span className="badge badge-warning font-size-12">
                                Open
                              </span>
                            )}
                          </b>
                        </h3>
                      </div>
                      <hr />
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          Created at :
                          <small className="ml-2 font-weight-bold">
                            {new Date(
                              this.state.tx.submissionDate,
                            ).toLocaleString()}
                          </small>
                        </h3>
                      </div>
                      <hr />
                      {!this.state.tx.isExecuted &&
                        this.state.tx.confirmations.length <
                          this.state.threshold && (
                          <Can
                            role={this.props.user.role}
                            perform="operator:financial"
                            yes={() => {
                              return (
                                <React.Fragment>
                                  <div className="mb-2">
                                    <Label>
                                      Select a signer to accept or reject
                                      proposal
                                    </Label>
                                  </div>
                                  <Row className="mt-2">
                                    {this.state.owners &&
                                      this.state.owners.map((owner, index) => (
                                        <Col xl="3" sm="12" key={owner}>
                                          <div className="mb-3">
                                            <label
                                              className="card-radio-label mb-2"
                                              onClick={() => {
                                                this.connectWallet(owner);
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                name="wallet"
                                                id={owner}
                                                className="card-radio-input"
                                                readOnly
                                              />

                                              <div className="card-radio">
                                                <div>
                                                  <h5 className="font-size-16 mb-1">
                                                    <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                                    <span>
                                                      {this.state.aliases[
                                                        owner.toLowerCase()
                                                      ] &&
                                                      this.state.aliases[
                                                        owner.toLowerCase()
                                                      ].name ? (
                                                        <span
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              this.state.aliases[
                                                                owner.toLowerCase()
                                                              ].name.replace(
                                                                '(',
                                                                '<br/>(',
                                                              ),
                                                          }}
                                                        ></span>
                                                      ) : (
                                                        truncStringPortion(
                                                          owner,
                                                          8,
                                                          6,
                                                        )
                                                      )}
                                                    </span>
                                                  </h5>
                                                  <div>
                                                    <p className="text-muted font-size-11 mb-1">
                                                      {truncStringPortion(
                                                        owner,
                                                        8,
                                                        6,
                                                      )}
                                                    </p>
                                                    <h5 className="font-size-16 mb-1">
                                                      {
                                                        this.state.balances[
                                                          owner
                                                        ]
                                                      }{' '}
                                                      <EthereumLogo
                                                        width="16"
                                                        height="16"
                                                      />
                                                    </h5>
                                                    <span className="text-muted">
                                                      ~
                                                      {Math.floor(
                                                        this.state.balances[
                                                          owner
                                                        ] / 0.002,
                                                      )}{' '}
                                                      operations
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </label>
                                          </div>
                                        </Col>
                                      ))}
                                  </Row>
                                </React.Fragment>
                              );
                            }}
                          />
                        )}
                      {this.state.signer && this.state.isSigner === 'valid' && (
                        <React.Fragment>
                          <Label className="mt-4">Connected acccount</Label>
                          <p>
                            <small>
                              {this.state.isSigner && 'signer: '}{' '}
                              <strong>{this.state.signer}</strong>
                            </small>
                          </p>
                        </React.Fragment>
                      )}
                      {this.state.signer && this.state.toValidate && (
                        <>
                          <p>Set same amount to confirm operation</p>
                          <NumberFormat
                            thousandSeparator={true}
                            prefix={'€'}
                            className="form-control"
                            placeholder={'€ amount'}
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              this.setState({
                                amount: value,
                                formattedAmount: formattedValue,
                              });
                            }}
                          />
                          {this.state.invalidAmount && (
                            <p className="badge badge-pill badge-danger font-size-12 mt-2">
                              Invalid amount validation
                            </p>
                          )}
                          <hr />
                        </>
                      )}
                      {this.state.signer &&
                        this.state.isSigner === 'invalid' && (
                          <p className="badge badge-pill badge-danger font-size-12">
                            Not connected with correct account
                          </p>
                        )}
                      <Row>
                        {!this.state.isReject &&
                          this.state.signer &&
                          !this.state.processing &&
                          !this.state.success &&
                          !this.state.rejectance && (
                            <Col sm="6">
                              <Button
                                color="success"
                                onClick={() => {
                                  this.acceptOP();
                                }}
                              >
                                Accept operation
                              </Button>
                            </Col>
                          )}

                        {this.state.tx.confirmations.length >=
                          this.state.threshold &&
                          !this.state.tx.isExecuted &&
                          this.state.tx.dataDecoded && (
                            <Col sm="6">
                              <Button
                                color="info"
                                onClick={() => {
                                  this.executeOp();
                                }}
                              >
                                Execute operation
                              </Button>
                            </Col>
                          )}
                        {this.state.isReject &&
                          this.state.signer &&
                          !this.state.processing &&
                          !this.state.success &&
                          !this.state.rejectance && (
                            <Col sm="6">
                              <Button
                                color="danger"
                                onClick={() => {
                                  this.acceptOP();
                                }}
                              >
                                Close reject
                              </Button>
                            </Col>
                          )}
                        {!this.state.isReject &&
                          this.state.signer &&
                          !this.state.processing &&
                          !this.state.success &&
                          !this.state.rejectance && (
                            <Col sm="6">
                              <Button
                                color="danger"
                                className="ml-2"
                                onClick={() => {
                                  this.rejectOP();
                                }}
                              >
                                Reject operation
                              </Button>
                            </Col>
                          )}
                        {this.state.processing && (
                          <p className="badge badge-pill badge-info font-size-12">
                            {this.state.info}
                          </p>
                        )}
                        {this.state.success && !this.state.isReject && (
                          <p className="badge badge-pill badge-success font-size-12 mt-4">
                            Operation successfully signed{' '}
                            {this.state.operationHash}
                          </p>
                        )}
                        {this.state.success && this.state.isReject && (
                          <p className="badge badge-pill badge-success font-size-12 mt-4">
                            Operation successfully rejected
                          </p>
                        )}
                        {this.state.rejectance && (
                          <p className="badge badge-pill badge-success font-size-12 mt-4">
                            Operation successfully rejected
                          </p>
                        )}
                      </Row>
                      <div className="d-print-none">
                        <div className="float-right">
                          <Link
                            to="#"
                            onClick={this.printInvoice}
                            className="btn btn-success waves-effect waves-light mr-2"
                          >
                            <i className="fa fa-print"></i>
                          </Link>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(EthProposalDetails);
