import axios from 'axios';
import * as rdd from 'react-device-detect';
import { AUTH_TOKEN, API_URL } from '../constants';

const scApi = axios.create({
  baseURL: API_URL,
  timeout: 120000,
  headers: {},
});

// Add a request interceptor
scApi.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token || ''}`;
  }
  return config;
});

const call = async (method, path, data) => {
  try {
    return (await scApi[method.toLowerCase()](path, data)).data;
  } catch (e) {
    console.error(e);
  }

  return { status: 'ERROR' };
};

//Login a user using email password and 2FA code with Google Authenticator
export const loginUser = async (email, password, code, recaptcha) => {
  const device = {
    osName: rdd.osName,
    osVersion: rdd.osVersion,
    browserName: rdd.browserName,
  };
  let res = await call('POST', '/auth/login', {
    email,
    password,
    code,
    device,
    recaptcha,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(
      JSON.stringify({
        err: res.error || res.message || 'UNKNOWN',
        remaining: res.remaining || null,
      }),
    );
  }
};

//Login a user using TrustBuilder/inWebo
export const loginInwebo = async (email, recaptcha) => {
  const device = {
    osName: rdd.osName,
    osVersion: rdd.osVersion,
    browserName: rdd.browserName,
  };
  let res = await call('POST', '/auth/operator/trustbuilder', {
    email,
    device,
    recaptcha,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(
      JSON.stringify({
        err: res.error || res.message || 'UNKNOWN',
      }),
    );
  }
};

//Get current user
export const getUser = async () => {
  let res = await call('GET', '/user/check');
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

// Escrow balance
export const getEscrowAmount = async () => {
  let res = await call('GET', '/bank');
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

// Get bank operations
export const getBankOps = async () => {
  let res = await call('GET', '/bank/operations');
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

// GET Bank accounts
export const getBankAccounts = async () => {
  let res = await call('GET', '/bank/accounts');
  console.log(res);
  return res.data.banks;
};

// POST Reference a new bank account
export const refBank = async (accountName, bankName, accountNumber) => {
  let res = await call('POST', '/bank/account', {
    accountName,
    bankName,
    accountNumber,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// POST Reference a new bank operation
export const refBankOp = async (
  fromAccount,
  opDate,
  requestField,
  amount,
  currency,
  receiver,
  opType,
  requestId,
  isReserve,
) => {
  let res = await call('POST', '/bank/operation', {
    fromAccount,
    opDate,
    requestField,
    amount,
    currency,
    receiver,
    opType,
    requestId,
    isReserve,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Delete a given Bank operation
export const deleteBankOp = async (id) => {
  return call('DELETE', `/bank/operation/${id}`);
};

//Set escrow account amount
/* export const setEscrowAmount = async ({ amount }) => {
  let res = await call('POST', '/bank', { amount });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
}; */

//Get all users
export const getUsers = async () => {
  let res = await call('GET', '/user');
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

//Get notification
export const getNotificationConfig = async () => {
  let res = await call('GET', '/notification');
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

export const getSpecificNotificationConfig = async (id) => {
  let res = await call('GET', `/notification/${id}`);
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

export const createNotificationGroup = async ({ name }) => {
  let res = await call('POST', '/notification', {
    name,
  });

  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

export const updateNotificationGroup = async ({ name, alertId }) => {
  let res = await call('PUT', `/notification/${alertId}`, {
    name,
  });

  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

export const updateNotificationConfigEvent = async (alertId, evt, value) => {
  let res = await call('POST', `/notification/${alertId}/event`, {
    event: evt,
    active: value,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

export const updateNotificationConfigEmail = async (alertId, evt, value) => {
  let res = await call('POST', `/notification/${alertId}/email`, {
    email: evt,
    active: value,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

//Create a new user
export const createUser = async ({
  firstname,
  lastname,
  email,
  inwebo,
  role,
  client,
}) => {
  return call('POST', '/user', {
    firstname,
    lastname,
    email,
    inwebo,
    role,
    client,
  });
};

//Delete a given user
export const deleteUser = async (id) => {
  return call('DELETE', `/user/${id}`);
};

//Reset 2FA code
export const updateUser2FA = async (id) => {
  return call('PUT', `/user/${id}/mfa`);
};

//Reset Activation
export const updateActivation = async (id) => {
  return call('PUT', `/user/${id}/trustbuilder`);
};

//Update user's password
export const updateUserPassword = async (k, password) => {
  return call('PUT', `/auth/${k}/password`, { password });
};

//Authorize reset password
export const getResetPassword = async (email) => {
  return call('POST', `/auth/reset`, { email });
};

//Validate reset key
export const checkResetKey = async (k) => {
  return call('GET', `/auth/check-reset?k=${k}`);
};

//TODO: Reset password
export const forgetPassword = async (email) => {};

// FA2

//Create a new wallet saved, stored and secured on the platform
export const createWallet = async (name, passPhrase, description) => {
  let res = await call('POST', '/api/fa2/wallet/create', {
    name,
    passPhrase,
    description,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Retrieve RPC node
export const rpcNode = async () => {
  return call('GET', '/api/fa2/rpc_node');
};

//Restore a platform wallet using back up phrase i.e. mnemonic
export const restoreWallet = async (
  name,
  passPhrase,
  description,
  mnemonic,
) => {
  let res = await call('POST', '/api/fa2/wallet/restore', {
    name,
    passPhrase,
    description,
    mnemonic,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Generate a Tezos keystore file
export const fa2Download = async (passPhrase = null) => {
  let res = await call('POST', '/api/fa2/wallet/download', { passPhrase });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Un-reference a wallet (does delete the wallet file from the platform)
export const deleteWallet = async (publicKeyHash, passPhrase = null) => {
  let res = await call('POST', '/api/fa2/wallet/delete', {
    publicKeyHash,
    passPhrase,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Reveal a wallet on Tezos Blockchain
export const revealWallet = async (publicKeyHash, passPhrase) => {
  return call('POST', '/api/fa2/wallet/reveal', { publicKeyHash, passPhrase });
};

//Update wallet name &/or description
export const updateWallet = async (publicKeyHash, name, description = null) => {
  let res = await call('POST', '/api/fa2/wallet/update', {
    publicKeyHash,
    name,
    description,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//List all referenced wallets with information e.g. EUROP balance, XTZ balance, lst operations...
export const listAdresses = async () => {
  return call('GET', '/api/fa2/wallet/addresses');
};

//List all referenced wallets with information e.g. EUROP balance, XTZ balance, lst operations...
export const listMultisigs = async () => {
  return call('GET', '/api/fa2/wallet/multisigs');
};

//Balance of the reserve
export const reserveBalance = async () => {
  return call('GET', '/api/fa2/reserve/balance');
};

//Get signers and entrypoints for a given multisig e.g. c=minter & token contract
export const financialOps = async (c) => {
  return call('GET', `/api/fa2/financial-ops/${c}`);
};

//Get signers and entrypoints for a given multisig e.g. c=admin & token contract
export const rightsOps = async (c) => {
  return call('GET', `/api/fa2/rights-ops/${c}`);
};

//Get all proposals for given multiSig contract e.g. c=minter
export const contractProposals = async (c) => {
  return call('GET', `/api/fa2/proposals/${c}`);
};

//Get all proposals for given multiSig contract e.g. c=minter
export const fetchProposals = async (c) => {
  return call('GET', '/api/fa2/fetch/new_proposals');
};

//Get contract code
export const codeContract = async (c) => {
  return call('GET', `/api/fa2/code/${c}`);
};

//Get publish contract code
export const publishCode = async (c) => {
  return call('GET', `/api/fa2/publish/${c}`);
};

//All operations for a given contract
export const contractOps = async (c) => {
  return call('GET', `/api/fa2/operations/${c}`);
};

//Storage of a given contract
export const contractStorage = async (c) => {
  return call('GET', `/api/fa2/storage/${c}`);
};

//Details for a given operation
export const opDetails = async (op) => {
  return call('GET', `/api/fa2/operation/${op}`);
};

//Get content for a given bigmap key for a given contract
export const bigmapKey = async (contract, key) => {
  return call('GET', `/api/fa2/bigmap/${contract}/${key}`);
};

//All operations attached to a proposal ID e.g. createProposal, accept & reject
export const proposalOps = async (contract, proposalId) => {
  return call('GET', `/api/fa2/proposal/${contract}/${proposalId}`);
};

//Get all operations for a given tz1 address
export const addressDetails = async (publicKeyHash) => {
  return call('GET', `/api/fa2/address/${publicKeyHash}/details`);
};

//Call contract entrypoint
export const callEntrypoint = async (genParams, account, passPhrase, c) => {
  let res = await call('POST', `/api/fa2/entrypoint/${c}`, {
    genParams,
    account,
    passPhrase,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Originate contract
export const originateContract = async (
  storage,
  contract,
  account,
  passPhrase,
) => {
  let res = await call('POST', '/api/fa2/originate/contract', {
    storage,
    contract,
    account,
    passPhrase,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Originate FeesManager
export const originateFeesManager = async (
  storage,
  contractScript,
  account,
  passPhrase,
) => {
  let res = await call('POST', '/api/fa2/feesmanager/originate', {
    storage,
    contractScript,
    account,
    passPhrase,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Send FeesManager proposal
export const proposeFeesManager = async (
  account,
  contractId,
  passPhrase,
  proposalId,
) => {
  let res = await call('POST', '/api/fa2/feesmanager/proposal', {
    account,
    contractId,
    passPhrase,
    proposalId,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Originate RightsManager
export const originateRightsManager = async (
  storage,
  contractScript,
  account,
  passPhrase,
) => {
  let res = await call('POST', '/api/fa2/rightsmanager/originate', {
    storage,
    contractScript,
    account,
    passPhrase,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Send RightsManager proposal
export const proposeRightsManager = async (
  account,
  contractId,
  passPhrase,
  proposalId,
) => {
  let res = await call('POST', '/api/fa2/rightsmanager/proposal', {
    account,
    contractId,
    passPhrase,
    proposalId,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Get tezos public node
export const publicNode = async () => {
  return call('GET', '/api/fa2/tezos/node');
};

//Get EUROP parameters
export const salvusParameters = async () => {
  return call('GET', '/api/fa2/salvus');
};

//Transfer XTZ from a platform wallet
export const transferXTZ = async (account, passPhrase, amount, receiver) => {
  let res = await call('POST', '/api/fa2/transfer/xtz', {
    account,
    passPhrase,
    amount,
    receiver,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Register FA2 proposal
export const registerProposal = async (
  proposalId,
  proposer,
  multiSig,
  description,
  entrypoint,
  parameters,
  requestRef,
) => {
  let res = await call('POST', '/api/fa2/register/proposal', {
    proposalId,
    proposer,
    multiSig,
    description,
    entrypoint,
    parameters,
    requestRef,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Register operation
export const registerOperation = async (
  opHash,
  opType,
  opDescription,
  caller,
  contract,
  proposalId = null,
) => {
  let res = await call('POST', '/api/fa2/register/operation', {
    opHash,
    opType,
    opDescription,
    caller,
    contract,
    proposalId,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// ERC20 token storage data
export const fa2ClosePurchase = async (status, ref, opHash) => {
  return call('GET', `/api/fa2/purchase/${status}/${ref}/${opHash}`);
};

//Retrieve all OCA extracts for a given client
export const extractsOCA = async (publicKeyHash) => {
  return call('GET', `/api/fa2/oca/extracts/${publicKeyHash}`);
};

//Retrieve a given OCA extract
export const extractDetails = async (extractId) => {
  return call('GET', `/api/fa2/oca/extract/${extractId}`);
};

//Resolve extracts for clients
export const resolveExtract = async (extractId) => {
  return call('GET', `/api/fa2/oca/extract/resolve/${extractId}`);
};

//Cancel from a given OCA extract
export const cancelExtract = async (extractId) => {
  return call('GET', `/api/fa2/oca/extract/cancel/${extractId}`);
};

//Get OCA analytics for a given client and timeframe
export const ocaAnalytics = async (clientId, start, end) => {
  let res = await call('POST', '/api/fa2/oca/analytics', {
    clientId,
    start,
    end,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Retrieve an operation from conseilJS
export const conseilJSop = async (opHash) => {
  return call('GET', `/api/fa2/conseiljs/operation/${opHash}`);
};

//Retrieve current and first cycle index of Baker
export const bakerCycles = async () => {
  return call('GET', '/baker/cycles');
};

//Retrieve given cycle details of Baker
export const cycleDetails = async (number) => {
  return call('GET', `/baker/cycle/${number}`);
};

//Load Baking analytics based on dates
export const bakingAnalytics = async (start, end) => {
  let res = await call('POST', '/baker/analytics', { start, end });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Pay rewards to delegates
export const payRewards = async (delegates) => {
  let res = await call('POST', '/baker/pay/rewards', { delegates });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Fetch transactions for a given OCA user
export const fetchUserTx = async (clientId, uuid) => {
  return call('GET', `/api/fa2/oca/user/${clientId}/${uuid}`);
};

//Get all pending whitelistings per client
export const fetchPendingWhitelistings = async () => {
  return call('GET', '/api/fa2/oca/whitelistings/pending');
};

//Approve a given uuid for a given clientId
export const approveWhitelisting = async (clientId, uuid) => {
  return call('GET', `/api/fa2/oca/approve/whitelisting/${clientId}/${uuid}`);
};

//Reject a given uuid for a given clientId
export const rejectWhitelisting = async (clientId, uuid) => {
  return call('GET', `/api/fa2/oca/reject/whitelisting/${clientId}/${uuid}`);
};

// FA2 reserve
export const fa2Reserve = async () => {
  return call('GET', `/api/fa2/wallet/reserve`);
};

//Get all whitelistings for a given client
export const clientWhitelistings = async (clientId) => {
  return call('GET', `/api/fa2/oca/client/whitelistings/${clientId}`);
};

// QUOTE

//Fetch Quote
export const fetchQuote = async (currency) => {
  return call('GET', `/operating/quote/${currency}`);
};

//Register Quote
export const saveQuote = async (currency, quote) => {
  let res = await call('POST', '/operating/quote', { currency, quote });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// ERC20

// ERC20 token storage data
export const erc20Storage = async () => {
  return call('GET', `/api/erc20/storage`);
};

//List all referenced wallets with information e.g. EUROP balance, XTZ balance, lst operations...
export const listERC20Adresses = async () => {
  return call('GET', '/api/erc20/wallet/addresses');
};

//Fetch Ethereum config
export const fetchERC20config = async () => {
  return call('GET', '/api/erc20/config');
};

// POST Create ERC20 wallet
export const createERC20Wallet = async (name, passPhrase, description) => {
  let res = await call('POST', '/api/erc20/create/wallet', {
    name,
    passPhrase,
    description,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// POST Download ERC20 wallet
export const downloadERC20Wallet = async (passPhrase) => {
  let res = await call('POST', '/api/erc20/download/wallet', { passPhrase });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// ERC20 token storage data
export const erc20ClosePurchase = async (status, safeTxHash, opHash) => {
  return call('GET', `/api/erc20/purchase/${status}/${safeTxHash}/${opHash}`);
};

// ERC20 reserve
export const erc20Reserve = async () => {
  return call('GET', `/api/erc20/wallet/reserve`);
};

//Register ERC20 proposal
export const registerERC20Proposal = async (
  proposalId,
  proposer,
  multiSig,
  description,
  entrypoint,
  parameters,
  requestRef,
) => {
  let res = await call('POST', '/api/erc20/register/proposal', {
    proposalId,
    proposer,
    multiSig,
    description,
    entrypoint,
    parameters,
    requestRef,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Register ERC20 operation
export const registerERC20Operation = async (
  opHash,
  opType,
  opDescription,
  caller,
  contract,
  proposalId = null,
) => {
  let res = await call('POST', '/api/erc20/register/operation', {
    opHash,
    opType,
    opDescription,
    caller,
    contract,
    proposalId,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//List last transactions
export const ERC20txs = async () => {
  return call('GET', '/api/erc20/transactions');
};

//Transfer ERC20 EUROP from a platform wallet
export const transferERC20EURUS = async (
  account,
  passPhrase,
  amount,
  receiver,
) => {
  let res = await call('POST', '/api/erc20/transfer/token', {
    account,
    passPhrase,
    amount,
    receiver,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Transfer ETH from a platform wallet
export const transferETH = async (account, passPhrase, amount, receiver) => {
  let res = await call('POST', '/api/erc20/transfer/eth', {
    account,
    passPhrase,
    amount,
    receiver,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Execute proposal
export const executeProposal = async (role, safeTxHash) => {
  let res = await call('POST', '/api/erc20/execute', { role, safeTxHash });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// Polygon

// ERC20 token storage data
export const polygonStorage = async () => {
  return call('GET', `/api/polygon/storage`);
};

//List all referenced wallets with information e.g. EUROP balance, XTZ balance, lst operations...
export const listPolygonAdresses = async () => {
  return call('GET', '/api/polygon/wallet/addresses');
};

//Fetch Ethereum config
export const fetchPolygonconfig = async () => {
  return call('GET', '/api/polygon/config');
};

// ERC20 token storage data
export const polygonClosePurchase = async (status, safeTxHash, opHash) => {
  return call('GET', `/api/polygon/purchase/${status}/${safeTxHash}/${opHash}`);
};

// ERC20 reserve
export const polygonReserve = async () => {
  return call('GET', `/api/polygon/wallet/reserve`);
};

//Register ERC20 proposal
export const registerPolygonProposal = async (
  proposalId,
  proposer,
  multiSig,
  description,
  entrypoint,
  parameters,
  requestRef,
) => {
  let res = await call('POST', '/api/polygon/register/proposal', {
    proposalId,
    proposer,
    multiSig,
    description,
    entrypoint,
    parameters,
    requestRef,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Register ERC20 operation
export const registerPolygonOperation = async (
  opHash,
  opType,
  opDescription,
  caller,
  contract,
  proposalId = null,
) => {
  let res = await call('POST', '/api/polygon/register/operation', {
    opHash,
    opType,
    opDescription,
    caller,
    contract,
    proposalId,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//List last transactions
export const Polygontxs = async () => {
  return call('GET', '/api/polygon/transactions');
};

//Transfer ERC20 EUROP from a platform wallet
export const transferPolygonEURUS = async (
  account,
  passPhrase,
  amount,
  receiver,
) => {
  let res = await call('POST', '/api/polygon/transfer/token', {
    account,
    passPhrase,
    amount,
    receiver,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Transfer ETH from a platform wallet
export const transferMatic = async (account, passPhrase, amount, receiver) => {
  let res = await call('POST', '/api/polygon/transfer/eth', {
    account,
    passPhrase,
    amount,
    receiver,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// OPERATING

//Retrieve proposal details
export const proposalDetails = async (proposalId) => {
  return call('GET', `/operating/proposal/${proposalId}`);
};

// Get available chains
export const getChains = async () => {
  let res = await call('GET', '/operating/chains');
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    return {
      status: 'ERROR',
    };
  }
};

//Retrieve last send proposals
export const lastProposals = async (limit) => {
  return call('GET', `/operating/proposals/last/${limit}`);
};

//Get unreadOps
export const unreadOps = async () => {
  return call('GET', '/operating/notifications/operations');
};

//Get set Ops to read
export const setRead = async (lastclear) => {
  let res = await call('POST', '/operating/notifications/read', { lastclear });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//List of all referenced wallets with EUROP balance
export const listAliases = async () => {
  return call('GET', '/operating/aliases/all');
};

//List of all referenced wallets without any details but name, description and public Key hash
export const dictAliases = async () => {
  return call('GET', '/operating/aliases/dictionary');
};

// GET Clients /abstract/clients
export const absClients = async () => {
  return call('GET', '/operating/abstract/clients');
};

// POST Reference a client public Key Hash (Tezos address)
export const refClient = async (
  tezos,
  ethereum,
  name,
  email,
  ipAddress,
  logo,
  vatNumber,
  receivingBank,
) => {
  let res = await call('POST', '/operating/client/reference', {
    tezos,
    ethereum,
    name,
    email,
    ipAddress,
    logo,
    vatNumber,
    receivingBank,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// GET Clients /clients/list
export const listClients = async () => {
  return call('GET', `/operating/clients/list`);
};
// GET Clients /client/details/:publicKeyHash
export const detailsClient = async (publicKeyHash) => {
  return call('GET', `/operating/client/details/${publicKeyHash}`);
};

// GET Retrieve client secret & token
export const credentialsClient = async (publicKeyHash) => {
  return call('GET', `/operating/client/credentials/${publicKeyHash}`);
};

//Regenerate client secret & token
export const regenerateCredentials = async (publicKeyHash) => {
  return call('GET', `/operating/client/regenerate/${publicKeyHash}`);
};

//Get client's idbanks
export const clientIdbanks = async (publicKeyHash) => {
  return call('GET', `/operating/idbanks/${publicKeyHash}`);
};

//Register new iban
export const saveIban = async (iban, bic, publicKeyHash = null) => {
  let res = await call('POST', '/operating/idbank/save', {
    iban,
    bic,
    publicKeyHash,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Delete IBAN
export const deleteIban = async (iban) => {
  return call('DELETE', `/operating/idbank/delete/${iban}`);
};

// POST Update Client Logo
export const updtClientLogo = async (publicKeyHash, logo) => {
  let res = await call('POST', '/operating/client/update/logo', {
    publicKeyHash,
    logo,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// POST Update Client Name
export const updtClientName = async (publicKeyHash, name) => {
  let res = await call('POST', '/operating/client/update/name', {
    publicKeyHash,
    name,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// POST Update Client Email
export const updtClientEmail = async (publicKeyHash, email) => {
  let res = await call('POST', '/operating/client/update/email', {
    publicKeyHash,
    email,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// POST Update Client vatNumber
export const updtClientVatNumber = async (publicKeyHash, vatNumber) => {
  let res = await call('POST', '/operating/client/update/vatnumber', {
    publicKeyHash,
    vatNumber,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// POST Update Client receivingBank
export const updtClientReceivingBank = async (publicKeyHash, receivingBank) => {
  let res = await call('POST', '/operating/client/update/receivingbank', {
    publicKeyHash,
    receivingBank,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// POST Update Client ipAddress
export const updtClientIpAddress = async (publicKeyHash, ipAddress) => {
  let res = await call('POST', '/operating/client/update/ipaddress', {
    publicKeyHash,
    ipAddress,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// GET Clients /client/fees/:publicKeyHash
export const feesClient = async (publicKeyHash) => {
  return call('GET', `/operating/client/fees/${publicKeyHash}`);
};

// GET Clients /client/fee/:publicKeyHash
export const feeClient = async (publicKeyHash) => {
  return call('GET', `/operating/client/fee/${publicKeyHash}`);
};

// POST Update Client Fee model
export const updtClientFees = async (
  publicKeyHash,
  mint,
  burn,
  euroFees,
  cryptoFees,
  baseEuro,
  baseCrypto,
  revenues,
  startDate,
  endDate,
) => {
  let res = await call('POST', '/operating/client/update/fees', {
    publicKeyHash,
    mint,
    burn,
    euroFees,
    cryptoFees,
    baseEuro,
    baseCrypto,
    revenues,
    startDate,
    endDate,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// POST Reference a client wallet
export const refWalletClient = async (wallet, name, publicKeyHash, chain) => {
  let res = await call('POST', '/operating/client/wallet', {
    wallet,
    name,
    publicKeyHash,
    chain,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Delete a client wallet
export const deleteWalletClient = async (publicKeyHash) => {
  return call('DELETE', `/operating/client/wallet/${publicKeyHash}`);
};

//Reference a Ledger wallet
export const referenceSigner = async (
  publicKeyHash,
  name,
  description,
  chain,
) => {
  let res = await call('POST', '/operating/reference-signer', {
    publicKeyHash,
    name,
    description,
    chain,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

// GET Base fee
export const getBaseFee = async () => {
  return call('GET', `/operating/basefee`);
};

// POST Update Base Fee
export const updtBaseFee = async (chain, euro) => {
  let res = await call('POST', '/operating/basefee/update', { chain, euro });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Register new request
export const saveRequest = async (
  reference,
  amount,
  description,
  iban,
  action,
  publicKeyHash,
  chain,
) => {
  let res = await call('POST', '/operating/request/save', {
    reference,
    amount,
    description,
    iban,
    action,
    publicKeyHash,
    chain,
  });
  if (res.status === 'SUCCESS') {
    return res;
  } else {
    throw new Error(res.error || 'UNKNOWN');
  }
};

//Cancel request
export const cancelRequest = async (reference) => {
  return call('DELETE', `/operating/cancel/${reference}`);
};

//Fetch requests for all clients
export const allRequests = async () => {
  return call('GET', '/operating/all-requests');
};

//Close & complete given sale
export const closeSale = async (reference) => {
  return call('GET', `/operating/close-sale/${reference}`);
};

//Close & complete given purchase
export const closePurchase = async (reference) => {
  return call('GET', `/operating/close-purchase/${reference}`);
};

//Retrieve all logs / activities from the platform
export const retrieveActivity = async (limit) => {
  return call('GET', `/operating/logs/all/${limit}`);
};

//Retrieve all platform access back log
export const retrieveAccess = async (limit) => {
  return call('GET', `/operating/access/all/${limit}`);
};

//Retrieve nb pending registrations
export const nbPendingCompanies = async () => {
  return call('GET', `/operating/companies/pending`);
};

//Retrieve all registrations
export const listCompanies = async () => {
  return call('GET', `/operating/companies/listing`);
};

//Retrieve given registration (i.e. company & owners)
export const registrationDetails = async (id) => {
  return call('GET', `/operating/companies/details/${id}`);
};

//List of all referenced wallets with EUROP balance
export const getConfig = async () => {
  return call('GET', '/operating/config');
};

//Accept & reference company as new client
export const acceptCompany = async (id) => {
  return call('GET', `/operating/companies/accept/${id}`);
};

//Reject company as new client
export const rejectCompany = async (id) => {
  return call('GET', `/operating/companies/reject/${id}`);
};
